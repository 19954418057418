import { createStore } from 'vuex'
import getters from './getters'
import project from './modules/project'
import gallery from './modules/gallery'
import detail from './modules/detail'
import play from './modules/play'
import app from './modules/app'

export default createStore({
  getters,
  modules: {
    app,
    project,
    gallery,
    detail,
    play
  }
})
