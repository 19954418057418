import { sFullUpdate, sIsFull } from '@/constant'
import { getItem, setItem } from '@/utils/storage'
export default {
	namespaced: true,
	state: () => ({
		fullUpdate: getItem(sFullUpdate) || false,
		isFull: getItem(sIsFull) || false
	}),
	mutations: {
		setFullUpdate(state: any, param: boolean) {
			state.fullUpdate = param
			setItem(sFullUpdate, state.fullUpdate)
		},
		setIsFull(state: any, param: boolean) {
			state.isFull = param
			setItem(sIsFull, state.isFull)
		}
	},
	actions: {}
}
