import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/pageList',
    name: 'pageList',
    component: () => import('../views/pageList.vue')
  },
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home.vue')
  },
  {
    path: '/services',
    name: 'services',
    component: () => import('../views/services.vue')
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('../views/aboutUs.vue')
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: () => import('../views/contactUs.vue')
  },
  {
    path: '/question',
    name: 'question',
    component: () => import('../views/question.vue')
  },
  {
    path: '/aboutMINI',
    name: 'aboutMINI',
    component: () => import('../views/aboutMINI.vue')
  },
  {
    path: '/instructions',
    name: 'instructions',
    component: () => import('../views/instructions.vue')
  },
  // {
  //   path: '/project',
  //   name: 'project',
  //   component: () => import('../views/project.vue')
  // },
  // {
  //   path: '/gallery/:id',
  //   // path: '/gallery',
  //   name: 'gallery',
  //   component: () => import('../views/gallery.vue')
  // },
  // {
  //   path: '/detail/:id/:id2',
  //   // path: '/detail',
  //   name: 'detail',
  //   component: () => import('../views/detail.vue')
  // }

  {
    path: '/project',
    name: 'project',
    component: () => import('../views/project/index.vue')
  },
  {
    path: '/project/introduce',
    name: 'introduce',
    component: () => import('../views/project/introduce.vue')
  },
  {
    path: '/project/gallery',
    name: 'gallery',
    component: () => import('../views/project/gallery.vue')
  },
  {
    path: '/project/detail',
    name: 'detail',
    component: () => import('../views/project/detail.vue')
  },
  {
    path: '/videos/play',
    name: 'play',
    component: () => import('../views/videos/play.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
