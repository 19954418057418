import { setItem, getItem, removeAllItem } from '@/utils/storage'
import { sDetailItem, sDetailWellList, sDetailWellListIndex, sDetailWellIndex, sDetailPlate, sDetailCycle, sDetailManualList, sDetailManualIndex, sDetailUpdate } from '@/constant'
import { imageShowInfo, imageShowList } from '@/api/image'
// 详情：图片、视频
// 创建：视频

export default {
	namespaced: true,
	state: () => ({
		item: getItem(sDetailItem) || {}, // 当前大图|视频信息  || 同步更新 galleryItem 同容一致
		wellList: getItem(sDetailWellList) || [], // 当前孔位对应的子孔位列表，默认显示第一张
		wellListIndex: getItem(sDetailWellListIndex) || 0, // 默认显示第?张
		wellIndex: getItem(sDetailWellIndex) || 0, // 当前孔位 选择后更新数据
		plate: getItem(sDetailPlate) || {}, // 当前大图|视频：侧边信息 plateShape|plateStyle|wellIndexList
		cycle: getItem(sDetailCycle) || 1, // 轮次
		manualList: getItem(sDetailManualList) || [], // 手动项目： 侧边列表
		manualIndex: getItem(sDetailManualIndex) || 0, // 手动项目： 侧边列表-选中项
		update: getItem(sDetailUpdate) || false // 统管所有更新
	}),
	mutations: {
		setItem(state: any, param: any) {
			state.item = param
			setItem(sDetailItem, param)
		},
		setWellList(state: any, param: any) {
			state.wellList = param
			setItem(sDetailWellList, param)
		},
		setWellListIndex(state: any, param: any) {
			state.wellListIndex = param
			setItem(sDetailWellListIndex, param)
		},
		setPlate(state: any, param: any) {
			state.plate = param
			setItem(sDetailPlate, param)
		},
		setCycle(state: any, param: any) {
			state.cycle = param
			setItem(sDetailCycle, param)
		},
		setWellIndex(state: any, param: any) {
			state.wellIndex = param
			setItem(sDetailWellIndex, param)
		},
		setManualList(state: any, param: any) {
			state.manualList = param
			setItem(sDetailManualList, param)
		},
		setManualIndex(state: any, param: any) {
			state.manualIndex = param
			setItem(sDetailManualIndex, param)
		},
		setUpdate(state: any, param: any) {
			state.update = param
			setItem(sDetailUpdate, param)
		}
	},
	actions: {
		/**
		 * 据项目id获取图片列表
		 * 可同步获取：
		 * 		多孔板参数
		 * 		项目信息
		 * 参数配置： null或不传
		 * 		cycleIndex:null,wellIndex:null,subWellIndex:null => 项目所有图片列表
		 * 		wellIndex:null,subWellIndex:null,  => 指定轮次所有孔位的图片列表(用不到)
		 * 		cycleIndex:null,  => 指定孔位所有轮次的图片列表
		 * 暂不分页
		 *
		 * projectId 必传，其它组合：
		 */
		async getList(context?: any, param?: any) {
			const { content, message, success } = await imageShowList({
				...param,
				page: 0,
				size: 1000
			})
			// const { list, project, projectPlate } = content
			// if (success && content.list.length) {
			// 	if (list && list.length) {
			// 		if (param.typeName == 'Project') {
			// 		}
			// 		if (param.typeName == 'ProjectCycle') {
			// 		}
			// 		if (param.typeName == 'ProjectWell') {
			// 		}
			// 		if (param.typeName == 'ProjectCycleWell') {
			// 		}
			// 		context.commit('setItem', content.list[0])
			// 		// context.commit('setCycle', content.list[0].cycleIndex)
			// 		context.commit('setWellListIndex', 0)
			// 		context.commit('setWellList', content.list)
			// 	}
			// 	if (project) {
			// 		context.commit('project/setProject', content.project, { root: true })
			// 	}
			// 	if (projectPlate && projectPlate != null) {
			// 		context.commit('setPlate', content.projectPlate)
			// 	}
			// }
			// context.commit('setUpdate', false)
			return content
		},
		// 项目图片列表
		getProjectImageList({ dispatch }: any, param?: any) {
			param['typeName'] = 'Project'
			return dispatch('getList', param)
		},
		// 项目指定轮次 图片列表
		getProjectCycleImageList({ dispatch }: any, param?: any) {
			param['typeName'] = 'ProjectCycle'
			return dispatch('getList', param)
		},
		// 项目 指定孔位 | 指定孔位＆子孔位 图片列表
		getProjectWellImageList({ dispatch }: any, param?: any) {
			param['typeName'] = 'ProjectWell'
			return dispatch('getList', param)
		},
		// 项目 指定孔位 指定轮次 图片列表
		getProjectCycleWellImageList({ dispatch }: any, param?: any) {
			param['typeName'] = 'ProjectCycleWell'
			return dispatch('getList', param)
		},
		/**
		 * 据图片id，获取图片详情
		 * 可同步获取：
		 * 		多孔板参数
		 * 		项目信息
		 * 		当前孔位所有子孔位图片
		 */
		async getInfo(context?: any, param?: any) {
			const { content, message, success } = await imageShowInfo(param)
			if (success) {
				return content
				// const { image, project, projectPlate, subWellImageList } = content
				// if (image) {
				// 	context.commit('setItem', content.image)
				// 	// context.commit('setCycle', content.image.cycleIndex)
				// }
				// if (project) {
				// 	// context.commit('project/setProject', content.project, { root: true })
				// }
				// if (projectPlate) {
				// 	context.commit('setPlate', content.projectPlate)
				// }
				// if (subWellImageList) {
				// 	context.commit('setWellList', content.subWellImageList)
				// 	context.commit('setWellListIndex', 0)
				// }
			}
			// context.commit('setUpdate', false)
		}
	}
}
