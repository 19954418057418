const ip = 'api.gnanoai.com'

// 访问链接
export const url = `https://${ip}/`
export const wsUrl = `ws://${ip}/ws/${Math.ceil(Math.random() * 1000)}`

// 项目图片地址拼接
// size:S|M|L
interface objData {
  id: string //"20211108182915325"
  coverImagePath: string //\/0001"
  coverImageName: string //"00012"
}
export const imgUrl = (obj: objData, size: string = 'S', type: string = 'jpg') => {
  if (obj.coverImagePath == undefined || obj.coverImageName == undefined || obj.coverImagePath == null || obj.coverImageName == null) {
    return require('@/assets/images/project-default-face.png')
  } else {
    return `${url}images/${obj.id}${obj.coverImagePath}/${size}-${obj.coverImageName}.${type}`
  }
}

// 照片墙图片地址拼接
// size:S|M|L
interface obj2Data {
  projectId: string //"20211108182915325"
  imagePath: string //\/0001"
  videoPath: string //\/0001"
  imageName: string //"00012"
  id: string //"00012"
}
// 视频地址调用：  imgUrlG(obj,'V','mp4')
// http://124.223.216.194:9500/images/20220504110946079/0001/V-A1-0001.mp4

export const imgUrlG = (obj: obj2Data, size: string = 'S', type: string = 'jpg') => {
  if (type == 'jpg' && obj.imagePath && (obj.imagePath == undefined || obj.imagePath == null)) {
    return require('@/assets/images/project-default-face.png')
  } else if (type == 'mp4' && obj.videoPath && (obj.videoPath == undefined || obj.videoPath == null)) {
    return require('@/assets/images/project-default-face.png')
  } else {
    return `${url}images/${obj.projectId}${obj.imagePath || obj.videoPath}/${size}-${obj.imageName || obj.id}.${type}`
  }
}
