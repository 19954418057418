import { projectList } from '@/api/project'
import { setItem, getItem, removeAllItem } from '@/utils/storage'
import { sProject, sCycleIndex, sWellName, sWellIndex, sSubWellIndex } from '@/constant'

export default {
	namespaced: true,
	state: () => ({
		project: getItem(sProject) || {},
		cycleIndex: getItem(sCycleIndex) || '',
		wellName: getItem(sWellName) || '',
		wellIndex: getItem(sWellIndex) || '',
		subWellIndex: getItem(sSubWellIndex) || ''
	}),
	mutations: {
		setProject(state: any, param: any) {
			state.project = param
			setItem(sProject, param)
		}
	},
	actions: {}
}
