import { setItem, getItem, removeAllItem } from '@/utils/storage'
import { sGalleryTab, sGalleryItem, sGalleryType, sGalleryCycleIndex, sGalleryWellName, sGalleryChooseList, sGalleryUpdateList, sGalleryPlate, sGalleryWellIndex } from '@/constant'

export default {
	namespaced: true,
	state: () => ({
		tab: getItem(sGalleryTab) || 1, // 显示面板:第几项
		galleryItem: getItem(sGalleryItem) || {}, // 使用：视频详情、图片详情、视频合成
		galleryType: getItem(sGalleryType) || 'list-cycle',
		// choosed: getItem(GALLERYCHOOSED) || [],
		// isCycle: getItem(GALLERYISCYCLE) || true,
		cycleIndex: getItem(sGalleryCycleIndex) || 1,
		wellName: getItem(sGalleryWellName) || '1',
		chooseList: getItem(sGalleryChooseList) || [], // 勾选列表
		updateList: getItem(sGalleryUpdateList) || false, // 是否需要更新列表
		plate: getItem(sGalleryPlate) || {}, // 照片墙里的多孔板
		wellIndex: getItem(sGalleryWellIndex) || 0 // 孔位切换：当前孔位
	}),
	mutations: {
		setTab(state: any, param: any) {
			state.tab = param
			setItem(sGalleryTab, param)
		},
		setGalleryItem(state: any, param: any) {
			state.galleryItem = param
			setItem(sGalleryItem, param)
		},
		setGalleryType(state: any, param: any) {
			state.galleryType = param
			setItem(sGalleryType, param)
		},
		// setChoosed(state: any, param: any) {
		// 	state.choosed = param
		// 	setItem(GALLERYCHOOSED, param)
		// },
		// setIsCycle(state: any, param: any) {
		// 	setItem(GALLERYISCYCLE, param || !state.isCycle)
		// 	state.isCycle = param || !state.isCycle
		// },
		setCycleIndex(state: any, param: any) {
			state.cycleIndex = param
			setItem(sGalleryCycleIndex, param)
		},
		setWellName(state: any, param: any) {
			state.wellName = param
			setItem(sGalleryWellName, param)
		},
		setChooseList(state: any, param: any) {
			state.chooseList = param
			setItem(sGalleryChooseList, param)
		},
		setUpdateList(state: any, param: any) {
			state.updateList = param
			setItem(sGalleryUpdateList, param)
		},
		setPlate(state: any, param: any) {
			state.plate = param
			setItem(sGalleryPlate, param)
		},
		setWellIndex(state: any, param: any) {
			state.wellIndex = param
			setItem(sGalleryWellIndex, param)
		}
	},
	actions: {}
}
