import request from '@/utils/request'

// // 下载图片
// interface downloadData {
// 	imageIdList: Array<string>
// }
// export const imageDownload = (data: downloadData) => {
// 	return request({
// 		url: `/api/image/download`,
// 		method: 'POST',
// 		data
// 	})
// }

// // 下载图片
// export const imageDownloadName = (downloadName: string) => {
// 	return request({
// 		url: `/api/image/download/${downloadName}`,
// 		method: 'GET'
// 	})
// }

// // 下载图片：单张
// interface downloadImageData {
// 	imageId: number
// }
// export const imageDownloadImage = (data: downloadImageData) => {
// 	return request({
// 		url: `/api/image/downloadImage`,
// 		method: 'POST',
// 		data
// 	})
// }

// // 下载图片：单张-名字
// export const imageDownloadImageName = (imageName: string) => {
// 	return request({
// 		url: `/api/image/downloadImage/{imageName}`,
// 		method: 'GET'
// 	})
// }

// // getImage
// export const imageId = (imageId: number) => {
// 	return request({
// 		url: `/api/image/id/${imageId}`,
// 		method: 'GET'
// 	})
// }

// // 获得图片信息
// export const imageInfoImageId = (imageId: number) => {
// 	return request({
// 		url: `/api/image/info/${imageId}`,
// 		method: 'GET'
// 	})
// }

// // imageShowInfo
// interface showInfoData {
// 	imageId: number
// 	withPlateInfo: boolean
// 	withProjectInfo: boolean
// 	withSubWellImageList: boolean
// }
// export const imageShowInfo = (data: showInfoData) => {
// 	return request({
// 		url: `/api/image/show/info`,
// 		method: 'POST',
// 		data
// 	})
// }

// // imageShowList
// interface showListData {
// 	cycleIndex: number
// 	isDescend: boolean
// 	orderBy: string
// 	page: number
// 	projectId: string
// 	size: number
// 	subWellIndex: number
// 	wellIndex: number
// 	withPlateInfo: boolean
// 	withProjectInfo: boolean
// }
// export const imageShowList = (data: showListData) => {
// 	return request({
// 		url: `/api/image/show/list`,
// 		method: 'POST',
// 		data
// 	})
// }

// 删除图片(单张或多张)
interface deleteData {
	imageIdList: Array<number>
}
export const imageDelete = (data: deleteData) => {
	return request({
		url: 'api/image/delete',
		method: 'POST',
		data
	})
}
// 删除图片(单张：观测，删除会推送ws)
export const imageDeleteOne = (imageId: number) => {
	return request({
		url: `api/image/delete/${imageId}`,
		method: 'GET'
	})
}

// 下载图片
interface downloadData {
	imageIdList: Array<string>
}
export const imageDownload = (data: downloadData) => {
	return request({
		url: 'api/image/download',
		method: 'POST',
		data
	})
}
// 下载图片：单张
interface downloadImageData {
	imageId: number
}
export const imageDownloadImage = (data: downloadImageData) => {
	return request({
		url: 'api/image/downloadImage',
		method: 'POST',
		data
	})
}

// 获得图片信息
export const imageInfo = (imageId: string) => {
	return request({
		url: `api/image/info/${imageId}`,
		method: 'GET'
	})
}

// 获取自由图片列表接口
interface listFreeData {
	page: number
	size: number
	isDescend: boolean
	oderBy: string
	projectId: string
}
export const imageListFree = (data: listFreeData) => {
	return request({
		url: 'api/image/list/free',
		method: 'POST',
		data
	})
}

// 获取图片列表接口-多孔板展示
interface listPlateData {
	projectId: string
	cycleIndex: string
}
export const imageListPlate = (data: listPlateData) => {
	return request({
		url: `api/image/list/plate/${data.cycleIndex}`,
		method: 'POST',
		data
	})
}

// 获取图片列表接口
interface listTableData {
	page: number
	size: number
	isDescend: boolean
	oderBy: string
	projectId: string
}
export const imageListTable = (data: listTableData) => {
	return request({
		url: 'api/image/list/table',
		method: 'POST',
		data
	})
}

// 获取图片缩略图列表接口-轮次
interface listThumbCycleData {
	page: number
	size: number
	projectId: string
	cycleIndex: string
}
export const imageListThumbCycle = (data: listThumbCycleData) => {
	return request({
		url: `api/image/list/thumb/cycle/${data.cycleIndex}`,
		method: 'POST',
		data
	})
}

// 获取图片缩略图列表接口-孔位
interface listThumbWellData {
	page: number
	size: number
	projectId: string
	wellName: string
}
export const imageListThumbWell = (data: listThumbWellData) => {
	return request({
		url: `api/image/list/thumb/well/${data.wellName}`,
		method: 'POST',
		data
	})
}

// 图片右边栏 (废弃)
export const imageShow = (imageId: string) => {
	return request({
		url: `api/image/show/${imageId}`,
		method: 'GET'
	})
}

// 获取自由图片列表 - 右边栏 (废弃)
interface showFreeData {
	page: number
	size: number
	isDescend: boolean
	projectId: string
}
export const imageShowFree = (data: showFreeData) => {
	return request({
		url: 'api/image/show/free',
		method: 'POST',
		data
	})
}
// 获取图片详情
interface showInfoData {
	imageId: number
	withPlateInfo: boolean
	withProjectInfo: boolean
	withSubWellImageList: boolean
}
export const imageShowInfo = (data: showInfoData) => {
	return request({
		url: 'api/image/show/info',
		method: 'POST',
		data
	})
}

// 获取图片详情-切换轮次或孔位内图片
interface showListData {
	projectId: string
	wellIndex: number | undefined // 孔位序号 null为所有孔位
	subWellIndex: number | undefined // 子孔位序号
	cycleIndex: number | undefined // null 为所有轮次
	isDescend: boolean
	page: number
	size: number
	withPlateInfo: boolean // 是否返回多孔板信息
	withProjectInfo: boolean // 是否返回项目信息
}
export const imageShowList = (data: showListData) => {
	return request({
		url: 'api/image/show/list',
		method: 'POST',
		data
	})
}

// 图片切换
interface showNextData {
	cycleIndex: number
	projectId: string
	subWellIndex: number
	wellIndex: number
}
export const imageShowNext = (data: showNextData) => {
	return request({
		url: 'api/image/show/next',
		method: 'POST',
		data
	})
}

// 更新图片信息
interface updateData {
	comment: string
	id: string
}
export const imageUpdate = (data: updateData) => {
	return request({
		url: 'api/image/update',
		method: 'POST',
		data
	})
}
