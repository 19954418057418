const getters = {
	fullUpdate: (state: any) => state.app.fullUpdate,
	isFull: (state: any) => state.app.isFull,
	// 项目
	project: (state: any) => state.project.project,
	cycleIndex: (state: any) => state.project.cycleIndex,
	wellName: (state: any) => state.project.wellName,
	wellIndex: (state: any) => state.project.wellIndex,
	subWellIndex: (state: any) => state.project.subWellIndex,
	// 照片墙
	galleryTab: (state: any) => state.gallery.tab,
	galleryItem: (state: any) => state.gallery.galleryItem,
	galleryType: (state: any) => state.gallery.galleryType,
	galleryCycleIndex: (state: any) => state.gallery.cycleIndex,
	galleryWellName: (state: any) => state.gallery.wellName,
	galleryChooseList: (state: any) => state.gallery.chooseList,
	galleryUpdateList: (state: any) => state.gallery.updateList,
	galleryPlate: (state: any) => state.gallery.plate,
	galleryWellIndex: (state: any) => state.gallery.wellIndex,
	// 详情：图片、视频
	detailItem: (state: any) => state.detail.item,
	detailWellList: (state: any) => state.detail.wellList,
	detailWellListIndex: (state: any) => state.detail.wellListIndex,
	detailWellIndex: (state: any) => state.detail.wellIndex,
	detailPlate: (state: any) => state.detail.plate,
	detailCycle: (state: any) => state.detail.cycle,
	detailManualList: (state: any) => state.detail.manualList,
	detailManualIndex: (state: any) => state.detail.manualIndex,
	detailUpdate: (state: any) => state.detail.update,
	// 合成视频播放
	playList: (state: any) => state.play.list,
	playInfo: (state: any) => state.play.info,
	playItem: (state: any) => state.play.item,
	playUpdate: (state: any) => state.play.update
}
export default getters
