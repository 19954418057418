import { setItem, getItem, removeAllItem } from '@/utils/storage'
import { sPlayList, sPlayInfo, sPlayItem, sPlayUpdate } from '@/constant'
// 合成视频播放详情页

export default {
	namespaced: true,
	state: () => ({
		list: getItem(sPlayList) || [], // 所有合成视频列表：侧边栏
		info: getItem(sPlayInfo) || {}, // 当前播放视频信息
		item: getItem(sPlayItem) || {}, //
		update: getItem(sPlayUpdate) || {} //
	}),
	mutations: {
		setList(state: any, param: any) {
			state.list = param
			setItem(sPlayList, param)
		},
		setInfo(state: any, param: any) {
			state.info = param
			setItem(sPlayInfo, param)
		},
		setItem(state: any, param: any) {
			state.item = param
			setItem(sPlayItem, param)
		},
		setUpdate(state: any, param: any) {
			state.update = param
			setItem(sPlayUpdate, param)
		}
	},
	actions: {}
}
