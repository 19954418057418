import axios from 'axios'
import store from '@/store'
import { ElMessage } from 'element-plus'
import { url } from '@/utils/baseUrl'
import { removeAllItem } from '@/utils/storage'

const service = axios.create({
	baseURL: url,
	timeout: 5000
})
// 请求拦截
// service.interceptors.request.use(
// 	(config: any) => {
// 		if (store.getters.token) {
// 			config.headers.token = `${store.getters.token}`
// 		}
// 		return config
// 	},
// 	err => {
// 		return Promise.reject(err)
// 	}
// )
// 响应拦截
service.interceptors.response.use(
	res => {
		const { success, message, content } = res.data
		if (success) {
			return res.data
		} else {
			// 业务错误
			return res.data
			// return Promise.reject(new Error(message))
		}
	},
	err => {
		if (err.message == 'Request failed with status code 401') {
			store.dispatch('user/loginOut', 401)
		}
		return Promise.reject(new Error(err))
	}
)
export default service
