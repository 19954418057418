export const sFullUpdate = 'fullUpdate'
export const sIsFull = 'isFull'

// 项目
export const sProject = 'project'
export const sCycleIndex = 'cycleIndex'
export const sWellName = 'wellName'
export const sWellIndex = 'wellIndex'
export const sSubWellIndex = 'subWellIndex'

// 照片墙
export const sGalleryTab = 'galleryTab'
export const sGalleryItem = 'galleryItem'
export const sGalleryType = 'galleryType'
export const sGalleryCycleIndex = 'galleryCycleIndex'
export const sGalleryWellName = 'galleryWellName'
export const sGalleryChooseList = 'galleryChooseList'
export const sGalleryUpdateList = 'galleryUpdateList'
export const sGalleryPlate = 'galleryPlate'
export const sGalleryWellIndex = 'galleryWellIndex'

// 详情(图片)
export const sDetailItem = 'detailItem'
export const sDetailWellList = 'detailWellList'
export const sDetailWellListIndex = 'detailWellListIndex'
export const sDetailWellIndex = 'detailWellIndex'
export const sDetailPlate = 'detailPlate'
export const sDetailCycle = 'detailCycle'
export const sDetailManualList = 'detailManualList'
export const sDetailManualIndex = 'detailManualIndex'
export const sDetailUpdate = 'detailUpdate'

// 合成视频播放
export const sPlayList = 'playList'
export const sPlayInfo = 'playInfo'
export const sPlayItem = 'playItem'
export const sPlayUpdate = 'playUpdate'
